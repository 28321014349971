import { createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { DELETE_BLOG } from '../../Graphql/mutations';
import { GET_ALL_BLOGS, GET_BLOGS_BY_TAGS, GET_BLOG_BY_URL } from '../../Graphql/queries';
import { IBlogPost } from '../../Types/Blog';
import { getApiUrl } from '../../Utils/urls';
import { RootState } from '../store';

export interface Blog {
  author: string;
  blogCount: string;
  body: string;
  date: string;
  description: string;
  id: string;
  img: string;
  readTime: string;
  tags: string[];
  title: string;
  url: string;
}

export interface Pagination {
  paginate: boolean;
  page: number;
  pageSize: number;
}

export interface GetAllBlogsResponse {
  getAllBlogs: Array<IBlogPost>;
}

export interface IBlogState {
  blogs: Array<IBlogPost>;
  currentBlog: IBlogPost;
}

export interface GetAllBlogsInput {
  pagination: Pagination;
}

export interface GetBlogByUrlInput {
  blogUrl: string;
}

export interface GetBlogByUrlResponse {
  getBlogByUrl: Blog;
}

export interface DeleteBlogByIdInput {
  blogId: string;
  userId: string;
}

export interface DeletBlogByIdResponse {
  deleteBlog: 'SUCCESS' | 'FAILURE' | 'BLANK';
}

export interface GetBlogsByTagsInput {
  tags: string;
  pagination: Pagination;
}

export interface GetBlogsByTagsResponse {
  getAllBlogsByTags: Blog;
}

export const blogsApi = createApi({
  reducerPath: 'blogsApi',
  baseQuery: graphqlRequestBaseQuery({ url: getApiUrl() }),
  endpoints: (builder) => ({
    getAllBlogs: builder.query<GetAllBlogsResponse, GetAllBlogsInput>({
      query: ({ pagination }) => ({
        document: GET_ALL_BLOGS,
        variables: { pagination },
      }),
    }),
    getBlogByUrl: builder.query<GetBlogByUrlResponse, GetBlogByUrlInput>({
      query: ({ blogUrl }) => ({
        document: GET_BLOG_BY_URL,
        variables: { blogUrl },
      }),
    }),
    getBlogByTags: builder.query<GetBlogsByTagsResponse, GetBlogsByTagsInput>({
      query: ({ tags, pagination }) => ({
        document: GET_BLOGS_BY_TAGS,
        variables: { tags, pagination },
      }),
    }),
    deleteBlogById: builder.query<DeletBlogByIdResponse, DeleteBlogByIdInput>({
      query: ({ blogId, userId }) => ({
        document: DELETE_BLOG,
        variables: { blogId, userId },
      }),
    }),
  }),
});

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: [] as Array<IBlogPost>,
    currentBlog: {} as IBlogPost,
  } as IBlogState,
  reducers: {
    resetBlogs: (state) => {
      state.blogs = [];
    },
    getBlogs: (state, action) => {
      state.blogs = [...state.blogs, ...action.payload];
    },
    currentBlog: (state, action) => {
      state.currentBlog = action.payload;
    },
  },
});

export const { getBlogs, currentBlog, resetBlogs } = blogsSlice.actions;

export const selectCurrentBlog = (state: RootState) => state.blogs.currentBlog;
export const selectBlogs = (state: RootState) => state.blogs.blogs;

export const {
  useGetAllBlogsQuery,
  useLazyGetAllBlogsQuery,
  useGetBlogByUrlQuery,
  useLazyGetBlogByUrlQuery,
  useDeleteBlogByIdQuery,
  useLazyDeleteBlogByIdQuery,
  useGetBlogByTagsQuery,
  useLazyGetBlogByTagsQuery,
} = blogsApi;
