/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation addUser($userInfo: UserInput, $password: String) {\n    addUser(userInfo: $userInfo, password: $password) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n": types.AddUserDocument,
    "\n  mutation updateUserProfile($profileInfo: UserProfileInput, $userId: String) {\n    updateUserProfile(profileInfo: $profileInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n": types.UpdateUserProfileDocument,
    "\n  mutation submitProperty($propertyInfo: PropertyInput, $userId: String) {\n    submitProperty(propertyInfo: $propertyInfo, userId: $userId)\n  }\n": types.SubmitPropertyDocument,
    "\n  mutation submitPropertyManual($propertyInfo: PropertyInput, $userId: String) {\n    submitPropertyManual(propertyInfo: $propertyInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n": types.SubmitPropertyManualDocument,
    "\n  mutation submitPropertyAirbnb($address: String, $link: String, $userId: String) {\n    submitPropertyAirbnb(address: $address, link: $link, userId: $userId) {\n      message\n      messageType\n    }\n  }\n": types.SubmitPropertyAirbnbDocument,
    "\n  mutation submitBlog($blogInfo: BlogInfo, $userId: String) {\n    submitBlog(blogInfo: $blogInfo, userId: $userId)\n  }\n": types.SubmitBlogDocument,
    "\n  mutation deleteBlog($blogId: String, $userId: String) {\n    deleteBlog(blogId: $blogId, userId: $userId)\n  }\n": types.DeleteBlogDocument,
    "\n  mutation updateProperty($propertyInfo: PropertyInput, $userId: String) {\n    updateProperty(propertyInfo: $propertyInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n": types.UpdatePropertyDocument,
    "\n  mutation likeProperty($propertyId: String, $userId: String) {\n    likeProperty(propertyId: $propertyId, userId: $userId)\n  }\n": types.LikePropertyDocument,
    "\n  mutation unlikeProperty($propertyId: String, $userId: String) {\n    unlikeProperty(propertyId: $propertyId, userId: $userId)\n  }\n": types.UnlikePropertyDocument,
    "\n  mutation createReservation(\n    $propertyId: String\n    $userId: String\n    $start: String\n    $end: String\n    $room: String\n    $totalCost: Float\n    $paymentSchedule: String\n    $message: String\n    $phone: String\n  ) {\n    createReservation(\n      propertyId: $propertyId\n      userId: $userId\n      start: $start\n      end: $end\n      room: $room\n      totalCost: $totalCost\n      paymentSchedule: $paymentSchedule\n      message: $message\n      phone: $phone\n    )\n  }\n": types.CreateReservationDocument,
    "\n  mutation cancelReservation($reservationId: String, $userId: String) {\n    cancelReservation(reservationId: $reservationId, userId: $userId)\n  }\n": types.CancelReservationDocument,
    "\n  mutation sendMessage($message: String, $toId: String, $fromId: String, $propertyId: String) {\n    sendMessage(message: $message, toId: $toId, fromId: $fromId, propertyId: $propertyId)\n  }\n": types.SendMessageDocument,
    "\n  mutation addSearch($searchTerm: String, $userId: String) {\n    addSearch(searchTerm: $searchTerm, userId: $userId)\n  }\n": types.AddSearchDocument,
    "\n  mutation sendReferral($referral: ReferralInput) {\n    sendReferral(referral: $referral) {\n      message\n      messageType\n    }\n  }\n": types.SendReferralDocument,
    "\n  mutation forgotPassword($email: String) {\n    forgotPassword(email: $email) {\n      message\n      messageType\n    }\n  }\n": types.ForgotPasswordDocument,
    "\n  mutation changePassword($userId: String, $password: String) {\n    changePassword(userId: $userId, password: $password) {\n      message\n      messageType\n    }\n  }\n": types.ChangePasswordDocument,
    "\n  query getAllProperties(\n    $searchCity: String\n    $searchStart: String\n    $searchEnd: String\n    $pagination: PaginationInput\n  ) {\n    getAllProperties(\n      searchCity: $searchCity\n      searchStart: $searchStart\n      searchEnd: $searchEnd\n      pagination: $pagination\n    ) {\n      id\n      name\n      title\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n      email\n    }\n  }\n": types.GetAllPropertiesDocument,
    "\n  query getAllPendingProperties($pagination: PaginationInput) {\n    getAllPendingProperties(pagination: $pagination) {\n      id\n      name\n      title\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n      email\n    }\n  }\n": types.GetAllPendingPropertiesDocument,
    "\n  query getSearchTermAndCountByTimeframe($timeframe: String) {\n    getSearchTermAndCountByTimeframe(timeframe: $timeframe) {\n      searchTerm\n      count\n    }\n  }\n": types.GetSearchTermAndCountByTimeframeDocument,
    "\n  query getAllCitiesAndStates {\n    getAllCitiesAndStates {\n      city\n      state\n    }\n  }\n": types.GetAllCitiesAndStatesDocument,
    "\n  query getPropertyById($propertyId: String) {\n    getPropertyById(propertyId: $propertyId) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n": types.GetPropertyByIdDocument,
    "\n  query getPropertyByTitle($propertyTitle: String) {\n    getPropertyByTitle(propertyTitle: $propertyTitle) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n": types.GetPropertyByTitleDocument,
    "\n  query getPropertyByName($propertyName: String) {\n    getPropertyByName(propertyName: $propertyName) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n": types.GetPropertyByNameDocument,
    "\n  query GetPropertiesByOwnerId($userId: String) {\n    getPropertiesByOwnerId(userId: $userId) {\n      addressLine1\n      addressLine2\n      amenities\n      baseRent\n      monthRent\n      baths\n      city\n      description\n      email\n      homeType\n      id\n      images\n      liked\n      location {\n        latitude\n        longitude\n      }\n      name\n      ownerFullName\n      ownerId\n      propertyCount\n      published\n      rooms\n      securityDeposit\n      source\n      sqft\n      state\n      title\n      totalRent\n      zipCode\n    }\n  }\n": types.GetPropertiesByOwnerIdDocument,
    "\n  query getAllArticles {\n    getAllArticles {\n      id\n      title\n      body\n    }\n  }\n": types.GetAllArticlesDocument,
    "\n  query checkUserCredentials($userInfo: UserInput, $password: String) {\n    checkUserCredentials(userInfo: $userInfo, password: $password) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n": types.CheckUserCredentialsDocument,
    "\n  query userEmailExists($userEmail: String) {\n    userEmailExists(userEmail: $userEmail)\n  }\n": types.UserEmailExistsDocument,
    "\n  query getUserProfile($userId: String) {\n    getUserProfile(userId: $userId) {\n      bio\n      email\n      firstName\n      gender\n      id\n      lastName\n      occupation\n      pets\n      phone\n      pictures\n      type\n    }\n  }\n": types.GetUserProfileDocument,
    "\n  query isPropertyLiked($propertyId: String, $userId: String) {\n    isPropertyLiked(propertyId: $propertyId, userId: $userId)\n  }\n": types.IsPropertyLikedDocument,
    "\n  query getLikedProperties($userId: String) {\n    getLikedProperties(userId: $userId) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n": types.GetLikedPropertiesDocument,
    "\n  query getFilteredProperties(\n    $searchCity: String\n    $filter: FilterInput\n    $pagination: PaginationInput\n  ) {\n    getFilteredProperties(searchCity: $searchCity, filter: $filter, pagination: $pagination) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n    }\n  }\n": types.GetFilteredPropertiesDocument,
    "\n  query getAllBlogs($pagination: PaginationInput) {\n    getAllBlogs(pagination: $pagination) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      blogCount\n      tags\n    }\n  }\n": types.GetAllBlogsDocument,
    "\n  query getBlogByUrl($blogUrl: String) {\n    getBlogByUrl(blogUrl: $blogUrl) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      tags\n    }\n  }\n": types.GetBlogByUrlDocument,
    "\n  query getAllBlogsByTags($pagination: PaginationInput, $tags: [String!]) {\n    getAllBlogsByTags(pagination: $pagination, tags: $tags) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      tags\n    }\n  }\n": types.GetAllBlogsByTagsDocument,
    "\n  query getMessageList($fromId: String) {\n    getMessageList(fromId: $fromId) {\n      content\n      room\n      modifiedOn\n      toId\n      toFirstName\n      toLastName\n      toDate\n      fromId\n      fromFirstName\n      fromLastName\n      fromDate\n    }\n  }\n": types.GetMessageListDocument,
    "\n  query getReservationList($userId: String) {\n    getReservationList(userId: $userId) {\n      id\n      room\n      totalCost\n      paymentSchedule\n      start\n      end\n      propertyId\n    }\n  }\n": types.GetReservationListDocument,
    "\n  query getAllAmenities {\n    getAllAmenities {\n      name\n    }\n  }\n": types.GetAllAmenitiesDocument,
    "\n  query getCalendarEventsByProperty($propertyId: String) {\n    getCalendarEventsByProperty(propertyId: $propertyId) {\n      start\n      end\n    }\n  }\n": types.GetCalendarEventsByPropertyDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addUser($userInfo: UserInput, $password: String) {\n    addUser(userInfo: $userInfo, password: $password) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation addUser($userInfo: UserInput, $password: String) {\n    addUser(userInfo: $userInfo, password: $password) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUserProfile($profileInfo: UserProfileInput, $userId: String) {\n    updateUserProfile(profileInfo: $profileInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation updateUserProfile($profileInfo: UserProfileInput, $userId: String) {\n    updateUserProfile(profileInfo: $profileInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation submitProperty($propertyInfo: PropertyInput, $userId: String) {\n    submitProperty(propertyInfo: $propertyInfo, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation submitProperty($propertyInfo: PropertyInput, $userId: String) {\n    submitProperty(propertyInfo: $propertyInfo, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation submitPropertyManual($propertyInfo: PropertyInput, $userId: String) {\n    submitPropertyManual(propertyInfo: $propertyInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation submitPropertyManual($propertyInfo: PropertyInput, $userId: String) {\n    submitPropertyManual(propertyInfo: $propertyInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation submitPropertyAirbnb($address: String, $link: String, $userId: String) {\n    submitPropertyAirbnb(address: $address, link: $link, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation submitPropertyAirbnb($address: String, $link: String, $userId: String) {\n    submitPropertyAirbnb(address: $address, link: $link, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation submitBlog($blogInfo: BlogInfo, $userId: String) {\n    submitBlog(blogInfo: $blogInfo, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation submitBlog($blogInfo: BlogInfo, $userId: String) {\n    submitBlog(blogInfo: $blogInfo, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteBlog($blogId: String, $userId: String) {\n    deleteBlog(blogId: $blogId, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation deleteBlog($blogId: String, $userId: String) {\n    deleteBlog(blogId: $blogId, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateProperty($propertyInfo: PropertyInput, $userId: String) {\n    updateProperty(propertyInfo: $propertyInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation updateProperty($propertyInfo: PropertyInput, $userId: String) {\n    updateProperty(propertyInfo: $propertyInfo, userId: $userId) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation likeProperty($propertyId: String, $userId: String) {\n    likeProperty(propertyId: $propertyId, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation likeProperty($propertyId: String, $userId: String) {\n    likeProperty(propertyId: $propertyId, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation unlikeProperty($propertyId: String, $userId: String) {\n    unlikeProperty(propertyId: $propertyId, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation unlikeProperty($propertyId: String, $userId: String) {\n    unlikeProperty(propertyId: $propertyId, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createReservation(\n    $propertyId: String\n    $userId: String\n    $start: String\n    $end: String\n    $room: String\n    $totalCost: Float\n    $paymentSchedule: String\n    $message: String\n    $phone: String\n  ) {\n    createReservation(\n      propertyId: $propertyId\n      userId: $userId\n      start: $start\n      end: $end\n      room: $room\n      totalCost: $totalCost\n      paymentSchedule: $paymentSchedule\n      message: $message\n      phone: $phone\n    )\n  }\n"): (typeof documents)["\n  mutation createReservation(\n    $propertyId: String\n    $userId: String\n    $start: String\n    $end: String\n    $room: String\n    $totalCost: Float\n    $paymentSchedule: String\n    $message: String\n    $phone: String\n  ) {\n    createReservation(\n      propertyId: $propertyId\n      userId: $userId\n      start: $start\n      end: $end\n      room: $room\n      totalCost: $totalCost\n      paymentSchedule: $paymentSchedule\n      message: $message\n      phone: $phone\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation cancelReservation($reservationId: String, $userId: String) {\n    cancelReservation(reservationId: $reservationId, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation cancelReservation($reservationId: String, $userId: String) {\n    cancelReservation(reservationId: $reservationId, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendMessage($message: String, $toId: String, $fromId: String, $propertyId: String) {\n    sendMessage(message: $message, toId: $toId, fromId: $fromId, propertyId: $propertyId)\n  }\n"): (typeof documents)["\n  mutation sendMessage($message: String, $toId: String, $fromId: String, $propertyId: String) {\n    sendMessage(message: $message, toId: $toId, fromId: $fromId, propertyId: $propertyId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addSearch($searchTerm: String, $userId: String) {\n    addSearch(searchTerm: $searchTerm, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation addSearch($searchTerm: String, $userId: String) {\n    addSearch(searchTerm: $searchTerm, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendReferral($referral: ReferralInput) {\n    sendReferral(referral: $referral) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation sendReferral($referral: ReferralInput) {\n    sendReferral(referral: $referral) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation forgotPassword($email: String) {\n    forgotPassword(email: $email) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation forgotPassword($email: String) {\n    forgotPassword(email: $email) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation changePassword($userId: String, $password: String) {\n    changePassword(userId: $userId, password: $password) {\n      message\n      messageType\n    }\n  }\n"): (typeof documents)["\n  mutation changePassword($userId: String, $password: String) {\n    changePassword(userId: $userId, password: $password) {\n      message\n      messageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllProperties(\n    $searchCity: String\n    $searchStart: String\n    $searchEnd: String\n    $pagination: PaginationInput\n  ) {\n    getAllProperties(\n      searchCity: $searchCity\n      searchStart: $searchStart\n      searchEnd: $searchEnd\n      pagination: $pagination\n    ) {\n      id\n      name\n      title\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n      email\n    }\n  }\n"): (typeof documents)["\n  query getAllProperties(\n    $searchCity: String\n    $searchStart: String\n    $searchEnd: String\n    $pagination: PaginationInput\n  ) {\n    getAllProperties(\n      searchCity: $searchCity\n      searchStart: $searchStart\n      searchEnd: $searchEnd\n      pagination: $pagination\n    ) {\n      id\n      name\n      title\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllPendingProperties($pagination: PaginationInput) {\n    getAllPendingProperties(pagination: $pagination) {\n      id\n      name\n      title\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n      email\n    }\n  }\n"): (typeof documents)["\n  query getAllPendingProperties($pagination: PaginationInput) {\n    getAllPendingProperties(pagination: $pagination) {\n      id\n      name\n      title\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSearchTermAndCountByTimeframe($timeframe: String) {\n    getSearchTermAndCountByTimeframe(timeframe: $timeframe) {\n      searchTerm\n      count\n    }\n  }\n"): (typeof documents)["\n  query getSearchTermAndCountByTimeframe($timeframe: String) {\n    getSearchTermAndCountByTimeframe(timeframe: $timeframe) {\n      searchTerm\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllCitiesAndStates {\n    getAllCitiesAndStates {\n      city\n      state\n    }\n  }\n"): (typeof documents)["\n  query getAllCitiesAndStates {\n    getAllCitiesAndStates {\n      city\n      state\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPropertyById($propertyId: String) {\n    getPropertyById(propertyId: $propertyId) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"): (typeof documents)["\n  query getPropertyById($propertyId: String) {\n    getPropertyById(propertyId: $propertyId) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPropertyByTitle($propertyTitle: String) {\n    getPropertyByTitle(propertyTitle: $propertyTitle) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"): (typeof documents)["\n  query getPropertyByTitle($propertyTitle: String) {\n    getPropertyByTitle(propertyTitle: $propertyTitle) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPropertyByName($propertyName: String) {\n    getPropertyByName(propertyName: $propertyName) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"): (typeof documents)["\n  query getPropertyByName($propertyName: String) {\n    getPropertyByName(propertyName: $propertyName) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      title\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPropertiesByOwnerId($userId: String) {\n    getPropertiesByOwnerId(userId: $userId) {\n      addressLine1\n      addressLine2\n      amenities\n      baseRent\n      monthRent\n      baths\n      city\n      description\n      email\n      homeType\n      id\n      images\n      liked\n      location {\n        latitude\n        longitude\n      }\n      name\n      ownerFullName\n      ownerId\n      propertyCount\n      published\n      rooms\n      securityDeposit\n      source\n      sqft\n      state\n      title\n      totalRent\n      zipCode\n    }\n  }\n"): (typeof documents)["\n  query GetPropertiesByOwnerId($userId: String) {\n    getPropertiesByOwnerId(userId: $userId) {\n      addressLine1\n      addressLine2\n      amenities\n      baseRent\n      monthRent\n      baths\n      city\n      description\n      email\n      homeType\n      id\n      images\n      liked\n      location {\n        latitude\n        longitude\n      }\n      name\n      ownerFullName\n      ownerId\n      propertyCount\n      published\n      rooms\n      securityDeposit\n      source\n      sqft\n      state\n      title\n      totalRent\n      zipCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllArticles {\n    getAllArticles {\n      id\n      title\n      body\n    }\n  }\n"): (typeof documents)["\n  query getAllArticles {\n    getAllArticles {\n      id\n      title\n      body\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query checkUserCredentials($userInfo: UserInput, $password: String) {\n    checkUserCredentials(userInfo: $userInfo, password: $password) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"): (typeof documents)["\n  query checkUserCredentials($userInfo: UserInput, $password: String) {\n    checkUserCredentials(userInfo: $userInfo, password: $password) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userEmailExists($userEmail: String) {\n    userEmailExists(userEmail: $userEmail)\n  }\n"): (typeof documents)["\n  query userEmailExists($userEmail: String) {\n    userEmailExists(userEmail: $userEmail)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserProfile($userId: String) {\n    getUserProfile(userId: $userId) {\n      bio\n      email\n      firstName\n      gender\n      id\n      lastName\n      occupation\n      pets\n      phone\n      pictures\n      type\n    }\n  }\n"): (typeof documents)["\n  query getUserProfile($userId: String) {\n    getUserProfile(userId: $userId) {\n      bio\n      email\n      firstName\n      gender\n      id\n      lastName\n      occupation\n      pets\n      phone\n      pictures\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query isPropertyLiked($propertyId: String, $userId: String) {\n    isPropertyLiked(propertyId: $propertyId, userId: $userId)\n  }\n"): (typeof documents)["\n  query isPropertyLiked($propertyId: String, $userId: String) {\n    isPropertyLiked(propertyId: $propertyId, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLikedProperties($userId: String) {\n    getLikedProperties(userId: $userId) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"): (typeof documents)["\n  query getLikedProperties($userId: String) {\n    getLikedProperties(userId: $userId) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFilteredProperties(\n    $searchCity: String\n    $filter: FilterInput\n    $pagination: PaginationInput\n  ) {\n    getFilteredProperties(searchCity: $searchCity, filter: $filter, pagination: $pagination) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n    }\n  }\n"): (typeof documents)["\n  query getFilteredProperties(\n    $searchCity: String\n    $filter: FilterInput\n    $pagination: PaginationInput\n  ) {\n    getFilteredProperties(searchCity: $searchCity, filter: $filter, pagination: $pagination) {\n      id\n      addressLine1\n      addressLine2\n      city\n      state\n      zipCode\n      location {\n        latitude\n        longitude\n      }\n      rooms\n      baths\n      sqft\n      description\n      amenities\n      securityDeposit\n      baseRent\n      monthRent\n      totalRent\n      images\n      published\n      liked\n      ownerId\n      propertyCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllBlogs($pagination: PaginationInput) {\n    getAllBlogs(pagination: $pagination) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      blogCount\n      tags\n    }\n  }\n"): (typeof documents)["\n  query getAllBlogs($pagination: PaginationInput) {\n    getAllBlogs(pagination: $pagination) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      blogCount\n      tags\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getBlogByUrl($blogUrl: String) {\n    getBlogByUrl(blogUrl: $blogUrl) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      tags\n    }\n  }\n"): (typeof documents)["\n  query getBlogByUrl($blogUrl: String) {\n    getBlogByUrl(blogUrl: $blogUrl) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      tags\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllBlogsByTags($pagination: PaginationInput, $tags: [String!]) {\n    getAllBlogsByTags(pagination: $pagination, tags: $tags) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      tags\n    }\n  }\n"): (typeof documents)["\n  query getAllBlogsByTags($pagination: PaginationInput, $tags: [String!]) {\n    getAllBlogsByTags(pagination: $pagination, tags: $tags) {\n      id\n      title\n      description\n      author\n      date\n      readTime\n      body\n      img\n      url\n      tags\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMessageList($fromId: String) {\n    getMessageList(fromId: $fromId) {\n      content\n      room\n      modifiedOn\n      toId\n      toFirstName\n      toLastName\n      toDate\n      fromId\n      fromFirstName\n      fromLastName\n      fromDate\n    }\n  }\n"): (typeof documents)["\n  query getMessageList($fromId: String) {\n    getMessageList(fromId: $fromId) {\n      content\n      room\n      modifiedOn\n      toId\n      toFirstName\n      toLastName\n      toDate\n      fromId\n      fromFirstName\n      fromLastName\n      fromDate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getReservationList($userId: String) {\n    getReservationList(userId: $userId) {\n      id\n      room\n      totalCost\n      paymentSchedule\n      start\n      end\n      propertyId\n    }\n  }\n"): (typeof documents)["\n  query getReservationList($userId: String) {\n    getReservationList(userId: $userId) {\n      id\n      room\n      totalCost\n      paymentSchedule\n      start\n      end\n      propertyId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllAmenities {\n    getAllAmenities {\n      name\n    }\n  }\n"): (typeof documents)["\n  query getAllAmenities {\n    getAllAmenities {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCalendarEventsByProperty($propertyId: String) {\n    getCalendarEventsByProperty(propertyId: $propertyId) {\n      start\n      end\n    }\n  }\n"): (typeof documents)["\n  query getCalendarEventsByProperty($propertyId: String) {\n    getCalendarEventsByProperty(propertyId: $propertyId) {\n      start\n      end\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;