import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { useAppDispatch, useAppSelector } from '../../../Hooks/useApp';
import { logout, selectCurrentUser } from '../../../State/slices/authentication';
import { searchCity, selectOwnerProperties } from '../../../State/slices/listing';
import { capitalizeInitials } from '../../../Utils/nameFunctions';

export const AccountMenu = ({ onLegacyClick }: { onLegacyClick: () => void }) => {
  const userState = useAppSelector(selectCurrentUser);
  const onwerPropertiesState = useAppSelector(selectOwnerProperties);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user: { id = '', firstName = '', lastName = '' } = {} } = userState;

  const hasProperties = onwerPropertiesState.length > 0;

  const initials = capitalizeInitials(`${firstName} ${lastName}`);

  const handleLogOut = () => {
    sessionStorage.setItem('userId', '');
    sessionStorage.setItem('userEmail', '');
    sessionStorage.setItem('userFirstName', '');
    sessionStorage.setItem('userLastName', '');
    sessionStorage.setItem('isInBeta', 'false');

    dispatch(logout());

    window.location.href = '/';
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title='Account settings'>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            {id ? (
              <Avatar sx={{ width: 32, height: 32 }}>{initials}</Avatar>
            ) : (
              <MenuIcon sx={{ width: 32, height: 32 }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            borderRadius: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {id ? (
          React.Children.toArray([
            hasProperties && (
              <MenuItem onClick={() => navigate('/dashboard')}>Dashboard</MenuItem>
            ),
            <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>,
            <Divider />,

            <MenuItem
              onClick={() => {
                dispatch(searchCity(''));
                navigate('/');
              }}
            >
              Listings
            </MenuItem>,
            <MenuItem
              onClick={() => (window.location.href = 'https://blog.flxlease.com/blog')}
            >
              Blog
            </MenuItem>,
            <MenuItem onClick={() => navigate('/contact-us')}>Contact Us</MenuItem>,
            <MenuItem onClick={() => navigate('/list-your-home')}>List Your Home</MenuItem>,
            <Divider />,
            <MenuItem
              onClick={() => {
                sessionStorage.setItem('isInBeta', 'false');
                onLegacyClick();
              }}
            >
              Legacy App
            </MenuItem>,
            <MenuItem
              onClick={() => {
                handleLogOut();
              }}
            >
              Logout
            </MenuItem>,
          ])
        ) : (
          <MenuItem onClick={() => navigate('/login')}>Login</MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};
