export const PathMap = {
  '/': 'Listings',
  '/listings': 'Listings',
  '/list-your-home': 'List Your Home',
  '/contact-us': 'Contact Us',
  '/profile': 'Profile',
};

export const getBreadCrumb = (pathname: string) => {
  if (pathname.includes('property')) return 'Property';
  // @ts-ignore
  const result = PathMap[pathname];

  if (!result) {
    return decodeURIComponent(pathname);
  }

  return result;
};
