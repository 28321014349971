import { ThemeProvider, createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#196666',
    },
    secondary: {
      main: '#F8B400',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary:hover': {
    //         backgroundColor: 'white',
    //         color: '#196666',
    //         borderColor: '#196666',
    //       },
    //     },
    //   },
    // },
    // @ts-ignore
    MuiDateRangePickerDay: {
      styleOverrides: {
        rangeIntervalDayHighlight: {
          backgroundColor: '#B2CCCC',
        },
        day: {
          '&.Mui-selected': {
            backgroundColor: '#196666',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#B2CCCC',
          },
          '&.Mui-selected': {
            backgroundColor: '#7FAAAA', // replace with your selected color
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#196666',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#196666',
          },
          fieldset: {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#196666',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-focused': {
            color: '#196666',
          },
          '&.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#196666',
            },
          },
          fieldset: {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#196666',
            color: 'white',
          },
        },
      },
    },
  },
});

export interface IThemeProps {
  children: React.ReactNode;
}

export const Theme = ({ children }: IThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
