import { COOKIE_CONSENT } from '../constants';
import { AmplitudeTracker, EVENT_TYPES, amplitude } from './amplitude.tracker';

export class AmplitudeEvents {
  constructor(apiKey: string) {
    const userConsents = localStorage.getItem('cookie-consent') === COOKIE_CONSENT.ACCEPTED;
    AmplitudeTracker.init(apiKey, !userConsents);
  }

  tryTrack = (fn: () => void) => {
    try {
      fn();
    } catch (error) {
      console.error('Error tracking event', error);
    }
  };

  listProperty(properties?: Record<string, any>) {
    this.tryTrack(() => {
      AmplitudeTracker.track({ name: EVENT_TYPES.LIST_PROPERTY, properties });
    });
  }

  logout() {
    this.tryTrack(() => {
      amplitude.reset();
    });
  }

  sendInquiry(properties?: Record<string, any>) {
    this.tryTrack(() =>
      AmplitudeTracker.track({ name: EVENT_TYPES.SEND_INQUIRY, properties }),
    );
  }

  signIn(properties: Record<string, any>) {
    this.tryTrack(() => {
      AmplitudeTracker.identifyUser(properties?.id, {
        email: properties?.email,
        firstName: properties?.firstName,
        lastName: properties?.lastName,
      });
      AmplitudeTracker.setSessionId(Date.now());
      AmplitudeTracker.track({ name: EVENT_TYPES.SIGN_IN, properties });
    });
  }

  signUp(properties: Record<string, any>) {
    this.tryTrack(() => {
      AmplitudeTracker.setUserId(properties.userId);
      AmplitudeTracker.setSessionId(Date.now());
      AmplitudeTracker.track({ name: EVENT_TYPES.SIGN_UP, properties });
    });
  }

  stillLooking(properties?: Record<string, any>) {
    this.tryTrack(() => {
      AmplitudeTracker.track({ name: EVENT_TYPES.STILL_LOOKING, properties });
    });
  }

  // TODO: We need an identify method...
}

const amplitudeEvents = new AmplitudeEvents(process.env.REACT_APP_AMPLITUDE_API_KEY || '');
export default amplitudeEvents;
