import { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Avatar } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { CityStates } from '../../../Graphql/Generated/graphql';

export interface SearchBarProps {
  cities: Array<CityStates>;
  onSelectedCityChanged?: (city: string) => void;
  search: (selectedCity: string) => void;
  isMobile?: boolean; // TODO:
}

export const SearchBarContainer = styled('div')`
  margin: 1%;
  padding: 0.5%;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  border: solid #b4b4b4 1px;
  border-radius: 50px;
  font-family: Helvetica;
  font-size: 0.9em;
  align-items: center;
  box-shadow: #eeeeee -2px 2px 5px;
  height: 50px;
`;

export const SelectCity = styled('div')`
  margin: 0 2% 0 1%;
  display: flex;
  flex-direction: column;
  width: 35%;
  font-weight: 600;
  text-align: center;
  padding: 1%;
  // &:hover {
  //   cursor: pointer;
  //   background: #005555;
  //   color: white;
  //   padding: 2.5% 1%;
  //   border-top-left-radius: 25px;
  //   border-bottom-left-radius: 25px;
  //   font-size: 0.9em;
  //   transform: scale(1.09);
  // }
`;

export const Divider = styled('div')`
  border-right: solid #b4b4b4 1px;
  height: 30px;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 65%;
  color: gray;
  padding: 2% 0;
  margin: 0 2%;
  font-size: 1em;
  &:hover {
    cursor: pointer;
  }
`;

export const SearchButton = styled('div')`
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  color: white;
  background: transparent;
  border: transparent;
  // position: relative;
  // right: 30px;
  // border: solid red 1px;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const Dropdown = styled('option')`
  text-align: center;
  border: solid red 1px;
`;

export const SearchBar = ({
  cities,
  onSelectedCityChanged,
  search,
  isMobile,
}: SearchBarProps) => {
  const [selectedCity, setSelectedCity] = useState('');
  // TODO: React Hook form...
  return (
    <SearchBarContainer sx={{ width: isMobile ? '330px' : '550px' }}>
      <SelectCity>Which City?</SelectCity>
      <Divider />
      <SearchContainer>
        <Autocomplete
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
          options={cities}
          groupBy={(cities) => cities.state}
          getOptionLabel={(option) => option.city}
          onChange={(event, value) => {
            let cityFormattedForSearch = '';

            if (value?.city && value?.state)
              cityFormattedForSearch = `${value?.city}, ${value?.state}`;

            onSelectedCityChanged?.(cityFormattedForSearch || '');
            setSelectedCity(cityFormattedForSearch || '');
          }}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option) => (
            <li {...props} key={`${option.state} ${option.city}`} value={option.city}>
              {option.city}
            </li>
          )}
        />
      </SearchContainer>
      <SearchButton onClick={() => search(selectedCity)}>
        <Avatar sx={{ bgcolor: '#005555' }}>
          <SearchIcon />
        </Avatar>
      </SearchButton>
    </SearchBarContainer>
  );
};
