import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const WhatsNewModal = ({ isOpen = false }: { isOpen: boolean }) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem('showBetaModalv1.2', 'false');
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='what-is-new-dialog-title'
      open={open}
      fullWidth
    >
      <DialogTitle id='what-is-new-dialog-title'>What&apos;s New</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography variant='overline' gutterBottom>
          Release 1.2 🎉
        </Typography>
        <Typography component='div' gutterBottom>
          While we are continuing our work on our next big feature for native payments and
          automating booking requests, here are some more small quality of life changes!
          <br />
          <br />
          <ul>
            <li>
              <Typography fontWeight='bold'>Booking</Typography>
              We&apos;re adding iCal links to the booking process to make it easier for you. We
              are also improving the experience around selecting dates for reservations and
              monthly pricing.
            </li>
            <li>
              <Typography fontWeight='bold'>List Your Home</Typography>
              We&apos;re modifying the amenities list to make it easier to select multiple
              amenities at once.
            </li>
            <li>
              <Typography fontWeight='bold'>Profile</Typography>
              We&apos;re allowing you to add an image to your profile.
            </li>
            <li>
              <Typography fontWeight='bold'>Search</Typography>
              We are updating the map to show details for each property in the map as you
              hover.
            </li>

            <li>
              <Typography fontWeight='bold'>General</Typography>
              We&apos;re fixing a few minor defects that some users have experienced! Stay
              tuned for future updates and our automated booking process coming soon!
            </li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Got It!</Button>
      </DialogActions>
    </Dialog>
  );
};
