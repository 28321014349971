export const capitalizeInitials = (inputString: string) => {
  const [firstWord = '', secondWord = ''] = inputString.split(' ');
  if (!firstWord || !secondWord) {
    return 'A';
  }
  return `${firstWord[0].toUpperCase()}${secondWord[0].toUpperCase()}`;
};

export const formatCityName = (city: string) =>
  city
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
