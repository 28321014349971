import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MessageContainer = styled('div')`
  z-index: 1;
  position: absolute;
`;

const ImageContainer = styled('div')`
  height: 800px;
  width: 100%;
  filter: brightness(85%);
`;

const Image = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  position: relative;
  filter: brightness(60%);
`;

export const ErrorPage = () => {
  return (
    <Container>
      <MessageContainer>
        <Typography variant='h1' color='white'>
          Oops! An Error. Occurred.
        </Typography>
      </MessageContainer>
      <ImageContainer>
        <Image src='../../imgs/Home.webp' alt='' loading='eager' />
      </ImageContainer>
    </Container>
  );
};

export default ErrorPage;
