import { graphql } from './Generated';

// Not in use
// export const GET_USERS = graphql(`
//   query {
//     users {
//       id
//       login
//       avatar_url
//     }
//   }
// `);

export const GET_ALL_PROPERTIES = graphql(`
  query getAllProperties(
    $searchCity: String
    $searchStart: String
    $searchEnd: String
    $pagination: PaginationInput
  ) {
    getAllProperties(
      searchCity: $searchCity
      searchStart: $searchStart
      searchEnd: $searchEnd
      pagination: $pagination
    ) {
      id
      name
      title
      addressLine1
      addressLine2
      city
      state
      zipCode
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
      propertyCount
      email
    }
  }
`);

export const GET_ALL_PENDING_PROPERTIES = graphql(`
  query getAllPendingProperties($pagination: PaginationInput) {
    getAllPendingProperties(pagination: $pagination) {
      id
      name
      title
      addressLine1
      addressLine2
      city
      state
      zipCode
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
      propertyCount
      email
    }
  }
`);

export const GET_SEARCH_TERM_AND_COUNT_BY_TIMEFRAME = graphql(`
  query getSearchTermAndCountByTimeframe($timeframe: String) {
    getSearchTermAndCountByTimeframe(timeframe: $timeframe) {
      searchTerm
      count
    }
  }
`);

// TODO: This query is used on the dashboard but does not exist in the schema
// export const GET_USERS_BY_TIMEFRAME = graphql(`
//   query getUsersByTimeframe($timeframe: String) {
//     getUsersByTimeframe(timeframe: $timeframe) {
//       id
//       firstName
//       lastName
//       email
//       createdOn
//       lastAccessedOn
//     }
//   }
// `);

export const GET_ALL_CITIES_AND_STATES = graphql(`
  query getAllCitiesAndStates {
    getAllCitiesAndStates {
      city
      state
    }
  }
`);

export const GET_PROPERTY_BY_ID = graphql(`
  query getPropertyById($propertyId: String) {
    getPropertyById(propertyId: $propertyId) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      title
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
    }
  }
`);

export const GET_PROPERTY_BY_TITLE = graphql(`
  query getPropertyByTitle($propertyTitle: String) {
    getPropertyByTitle(propertyTitle: $propertyTitle) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      title
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
    }
  }
`);

export const GET_PROPERTY_BY_NAME = graphql(`
  query getPropertyByName($propertyName: String) {
    getPropertyByName(propertyName: $propertyName) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      title
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
    }
  }
`);

export const GET_PROPERTIES_BY_OWNER_ID = graphql(`
  query GetPropertiesByOwnerId($userId: String) {
    getPropertiesByOwnerId(userId: $userId) {
      addressLine1
      addressLine2
      amenities
      baseRent
      monthRent
      baths
      city
      description
      email
      homeType
      id
      images
      liked
      location {
        latitude
        longitude
      }
      name
      ownerFullName
      ownerId
      propertyCount
      published
      rooms
      securityDeposit
      source
      sqft
      state
      title
      totalRent
      zipCode
    }
  }
`);

// Not in use
// export const GET_ALL_USERS = graphql(`
//   query {
//     getAllUsers {
//       first_name
//       last_name
//       phone
//       email
//     }
//   }
// `);

export const GET_ALL_ARTICLES = graphql(`
  query getAllArticles {
    getAllArticles {
      id
      title
      body
    }
  }
`);

// Not in use
// export const GET_ALL_PLACES_WITH_SPECS = graphql(`
//   query {
//     getAllPlacesWithSpecs {
//       address_line1
//       address_line2
//       city
//       state
//       zip_code
//       sqrft
//       bedrooms
//       bathrooms
//       price
//     }
//   }
// `);

export const CHECK_USER_CREDENTIALS = graphql(`
  query checkUserCredentials($userInfo: UserInput, $password: String) {
    checkUserCredentials(userInfo: $userInfo, password: $password) {
      id
      firstName
      lastName
      email
    }
  }
`);

export const USER_EMAIL_EXISTS = graphql(`
  query userEmailExists($userEmail: String) {
    userEmailExists(userEmail: $userEmail)
  }
`);

export const GET_USER_PROFILE = graphql(`
  query getUserProfile($userId: String) {
    getUserProfile(userId: $userId) {
      bio
      email
      firstName
      gender
      id
      lastName
      occupation
      pets
      phone
      pictures
      type
    }
  }
`);

export const IS_PROPERTY_LIKED = graphql(`
  query isPropertyLiked($propertyId: String, $userId: String) {
    isPropertyLiked(propertyId: $propertyId, userId: $userId)
  }
`);

export const GET_LIKED_PROPERTIES = graphql(`
  query getLikedProperties($userId: String) {
    getLikedProperties(userId: $userId) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
    }
  }
`);

export const GET_FILTERED_PROPERTIES = graphql(`
  query getFilteredProperties(
    $searchCity: String
    $filter: FilterInput
    $pagination: PaginationInput
  ) {
    getFilteredProperties(searchCity: $searchCity, filter: $filter, pagination: $pagination) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      location {
        latitude
        longitude
      }
      rooms
      baths
      sqft
      description
      amenities
      securityDeposit
      baseRent
      monthRent
      totalRent
      images
      published
      liked
      ownerId
      propertyCount
    }
  }
`);

export const GET_ALL_BLOGS = graphql(`
  query getAllBlogs($pagination: PaginationInput) {
    getAllBlogs(pagination: $pagination) {
      id
      title
      description
      author
      date
      readTime
      body
      img
      url
      blogCount
      tags
    }
  }
`);

export const GET_BLOG_BY_URL = graphql(`
  query getBlogByUrl($blogUrl: String) {
    getBlogByUrl(blogUrl: $blogUrl) {
      id
      title
      description
      author
      date
      readTime
      body
      img
      url
      tags
    }
  }
`);

export const GET_BLOGS_BY_TAGS = graphql(`
  query getAllBlogsByTags($pagination: PaginationInput, $tags: [String!]) {
    getAllBlogsByTags(pagination: $pagination, tags: $tags) {
      id
      title
      description
      author
      date
      readTime
      body
      img
      url
      tags
    }
  }
`);

export const GET_MESSAGE_LIST = graphql(`
  query getMessageList($fromId: String) {
    getMessageList(fromId: $fromId) {
      content
      room
      modifiedOn
      toId
      toFirstName
      toLastName
      toDate
      fromId
      fromFirstName
      fromLastName
      fromDate
    }
  }
`);

export const GET_RESERVATION_LIST = graphql(`
  query getReservationList($userId: String) {
    getReservationList(userId: $userId) {
      id
      room
      totalCost
      paymentSchedule
      start
      end
      propertyId
    }
  }
`);

export const GET_ALL_AMENITIES = graphql(`
  query getAllAmenities {
    getAllAmenities {
      name
    }
  }
`);

// Not in use
// export const GET_S3_INFORMATION = graphql(`
//   query {
//     getS3Information {
//       name
//       region
//       access_key_id
//       secret_access_key
//     }
//   }
// `);

export const GET_CALENDAR_EVENTS_BY_PROPERTY = graphql(`
  query getCalendarEventsByProperty($propertyId: String) {
    getCalendarEventsByProperty(propertyId: $propertyId) {
      start
      end
    }
  }
`);
