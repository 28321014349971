export const MESSAGE_TYPES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  BLANK: 'BLANK',
};

export const COOKIE_CONSENT = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const PHONE_REGEX = /^(\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const TERMS_TEXT =
  'By checking this box and listing your home on our website, you agree to a recurring commission fee of 7.5% on the duration of the stay facilitated by FLXlease for the term of the reservation request. This commission is payable on a month-to-month basis.';
