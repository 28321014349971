import { configureStore } from '@reduxjs/toolkit';

import { alertSlice } from './slices/alert';
import {
  authenticationApi,
  authenticationMiddleware,
  userSlice,
} from './slices/authentication';
import { blogsApi, blogsSlice } from './slices/blogs';
import { listingApi, listingMiddleware, listingSlice } from './slices/listing';

const store = configureStore({
  reducer: {
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [listingApi.reducerPath]: listingApi.reducer,
    user: userSlice.reducer,
    listing: listingSlice.reducer,
    [blogsApi.reducerPath]: blogsApi.reducer,
    blogs: blogsSlice.reducer,
    alert: alertSlice.reducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authenticationApi.middleware,
      listingApi.middleware,
      blogsApi.middleware,
      authenticationMiddleware,
      listingMiddleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
