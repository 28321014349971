import { Middleware, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { UserProfile } from '../../Graphql/Generated/graphql';
import { ADD_USER, CHANGE_PASSWORD, UPDATE_USER_PROFILE } from '../../Graphql/mutations';
import {
  CHECK_USER_CREDENTIALS,
  GET_USER_PROFILE,
  USER_EMAIL_EXISTS,
} from '../../Graphql/queries';
import amplitudeEvents from '../../Utils/Tracking/amplitude.events';
import { getApiUrl } from '../../Utils/urls';
import { RootState } from '../store';

export interface UserInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface CheckUserCredentials extends UserInput {
  id: string;
}

export interface SignInInput {
  userInfo: UserInput;
  password: string;
}

export interface SignInResponse {
  checkUserCredentials?: CheckUserCredentials;
}

export interface SignUpResponse {
  addUser?: CheckUserCredentials;
}

export interface UserEmailExistsResponse {
  userEmailExists?: boolean;
}

export interface UpdateUserProfileInput {
  profileInfo: Omit<UserProfile, 'id'>;
  userId?: string | null;
}

export type MessageType = 'SUCCESS' | 'FAILURE' | 'BLANK';

export interface UpdateUserProfileResponse {
  updateUserProfile: {
    message: string;
    messageType: MessageType;
  };
}

export interface UpdatePasswordInput {
  userId: string;
  password: string;
}

export interface UpdatePasswordResponse {
  message: string;
  messageType: MessageType;
}

export interface GetUserProfileResponse {
  getUserProfile: UserProfile;
}

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: graphqlRequestBaseQuery({ url: getApiUrl() }),
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInInput>({
      query: ({ userInfo, password }) => ({
        document: CHECK_USER_CREDENTIALS,
        variables: { userInfo, password },
      }),
    }),
    signUp: builder.mutation<SignUpResponse, SignInInput>({
      query: ({ userInfo, password }) => ({
        document: ADD_USER,
        variables: { userInfo, password },
      }),
    }),
    getUserProfile: builder.query<GetUserProfileResponse, { userId?: string }>({
      query: ({ userId }) => ({
        document: GET_USER_PROFILE,
        variables: { userId },
      }),
    }),
    userEmailExists: builder.query<UserEmailExistsResponse, { userEmail?: string }>({
      query: ({ userEmail }) => ({
        document: USER_EMAIL_EXISTS,
        variables: { userEmail },
      }),
    }),
    updateUserProfile: builder.mutation<UpdateUserProfileResponse, UpdateUserProfileInput>({
      query: ({ profileInfo, userId }) => ({
        document: UPDATE_USER_PROFILE,
        variables: { profileInfo, userId },
      }),
    }),
    updatePassword: builder.mutation<UpdatePasswordResponse, UpdatePasswordInput>({
      query: ({ userId, password }) => ({
        document: CHANGE_PASSWORD,
        variables: { userId, password },
      }),
    }),
  }),
});

export interface UserState extends UserInput {
  id: string;
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      id: sessionStorage.getItem('userId') || '',
      firstName: sessionStorage.getItem('userFirstName') || '',
      lastName: sessionStorage.getItem('userLastName') || '',
      email: sessionStorage.getItem('userEmail') || '',
    } as UserState,
  },
  reducers: {
    logout: (state) => {
      state.user = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authenticationApi.endpoints.signIn.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.checkUserCredentials || state.user;
      },
    );

    builder.addMatcher(
      authenticationApi.endpoints.signUp.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.addUser || state.user;
      },
    );
  },
});

export const authenticationMiddleware: Middleware = (storeAPI) => (next) => (action: any) => {
  // TODO:
  if (authenticationApi.endpoints.signIn.matchFulfilled(action)) {
    // We need to identify the user here...
    amplitudeEvents.signIn(action.payload.checkUserCredentials || {});
  }

  if (userSlice.actions.logout.match(action)) {
    amplitudeEvents.logout();
  }

  if (authenticationApi.endpoints.signUp.matchFulfilled(action)) {
    amplitudeEvents.signUp(action.payload.addUser || {});
  }

  return next(action);
};

export const {
  useSignInMutation,
  useSignUpMutation,
  useLazyUserEmailExistsQuery,
  useUpdatePasswordMutation,
  useUpdateUserProfileMutation,
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
} = authenticationApi;

export const { logout } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user;
