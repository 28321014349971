import { graphql } from './Generated';

// Not in use
// export const ADD_ARTICLE = graphql(`
//   mutation AddArticle($articleInput: CreateArticleInput, $userId: String) {
//     addArticle(articleInput: $articleInput, userId: $userId) {

//     }
//   }
// `);

export const ADD_USER = graphql(`
  mutation addUser($userInfo: UserInput, $password: String) {
    addUser(userInfo: $userInfo, password: $password) {
      id
      firstName
      lastName
      email
    }
  }
`);

export const UPDATE_USER_PROFILE = graphql(`
  mutation updateUserProfile($profileInfo: UserProfileInput, $userId: String) {
    updateUserProfile(profileInfo: $profileInfo, userId: $userId) {
      message
      messageType
    }
  }
`);

// Not in use
// export const SUBMIT_FEEDBACK = graphql(`
//   mutation submitFeedback(
//     $email: String
//     $firstName: String
//     $lastName: String
//     $feedback: String
//   ) {
//     submitFeedback(
//       email: $email
//       firstName: $firstName
//       lastName: $lastName
//       feedback: $feedback
//     )
//   }
// `);

export const SUBMIT_PROPERTY = graphql(`
  mutation submitProperty($propertyInfo: PropertyInput, $userId: String) {
    submitProperty(propertyInfo: $propertyInfo, userId: $userId)
  }
`);

export const SUBMIT_PROPERTY_MANUAL = graphql(`
  mutation submitPropertyManual($propertyInfo: PropertyInput, $userId: String) {
    submitPropertyManual(propertyInfo: $propertyInfo, userId: $userId) {
      message
      messageType
    }
  }
`);

export const SUBMIT_PROPERTY_AIRBNB = graphql(`
  mutation submitPropertyAirbnb($address: String, $link: String, $userId: String) {
    submitPropertyAirbnb(address: $address, link: $link, userId: $userId) {
      message
      messageType
    }
  }
`);

export const SUBMIT_BLOG = graphql(`
  mutation submitBlog($blogInfo: BlogInfo, $userId: String) {
    submitBlog(blogInfo: $blogInfo, userId: $userId)
  }
`);

export const DELETE_BLOG = graphql(`
  mutation deleteBlog($blogId: String, $userId: String) {
    deleteBlog(blogId: $blogId, userId: $userId)
  }
`);

export const UPDATE_PROPERTY = graphql(`
  mutation updateProperty($propertyInfo: PropertyInput, $userId: String) {
    updateProperty(propertyInfo: $propertyInfo, userId: $userId) {
      message
      messageType
    }
  }
`);

// Not in use
// export const SUBMIT_SURVEY = graphql(`
//   mutation submitSurvey(
//     $userEmail: String
//     $surveyQuestions: [String]
//     $surveyResponses: [String]
//   ) {
//     submitSurvey(
//       userEmail: $userEmail
//       surveyQuestions: $surveyQuestions
//       surveyResponses: $surveyResponses
//     )
//   }
// `);

export const LIKE_PROPERTY = graphql(`
  mutation likeProperty($propertyId: String, $userId: String) {
    likeProperty(propertyId: $propertyId, userId: $userId)
  }
`);

export const UNLIKE_PROPERTY = graphql(`
  mutation unlikeProperty($propertyId: String, $userId: String) {
    unlikeProperty(propertyId: $propertyId, userId: $userId)
  }
`);

export const CREATE_RESERVATION = graphql(`
  mutation createReservation(
    $propertyId: String
    $userId: String
    $start: String
    $end: String
    $room: String
    $totalCost: Float
    $paymentSchedule: String
    $message: String
    $phone: String
  ) {
    createReservation(
      propertyId: $propertyId
      userId: $userId
      start: $start
      end: $end
      room: $room
      totalCost: $totalCost
      paymentSchedule: $paymentSchedule
      message: $message
      phone: $phone
    )
  }
`);

export const CANCEL_RESERVATION = graphql(`
  mutation cancelReservation($reservationId: String, $userId: String) {
    cancelReservation(reservationId: $reservationId, userId: $userId)
  }
`);

export const SEND_MESSAGE = graphql(`
  mutation sendMessage($message: String, $toId: String, $fromId: String, $propertyId: String) {
    sendMessage(message: $message, toId: $toId, fromId: $fromId, propertyId: $propertyId)
  }
`);

export const ADD_SEARCH = graphql(`
  mutation addSearch($searchTerm: String, $userId: String) {
    addSearch(searchTerm: $searchTerm, userId: $userId)
  }
`);

export const SEND_REFERRAL = graphql(`
  mutation sendReferral($referral: ReferralInput) {
    sendReferral(referral: $referral) {
      message
      messageType
    }
  }
`);

export const FORGOT_PASSWORD = graphql(`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email) {
      message
      messageType
    }
  }
`);

export const CHANGE_PASSWORD = graphql(`
  mutation changePassword($userId: String, $password: String) {
    changePassword(userId: $userId, password: $password) {
      message
      messageType
    }
  }
`);
