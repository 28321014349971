import { useNavigate } from 'react-router-dom';

import './Fallback.css';

interface IPropsFallback {
  error: any;
  resetErrorBoundary: any;
  onTryHome: any;
}

export const Fallback: any = (props: IPropsFallback) => {
  const navigate = useNavigate();
  return (
    <div role='alert' className='fallbackContainer'>
      <div className='loginLogo'>
        <img className='logo2' src='../../imgs/FLXLEASE_LOGO.png' alt='' loading='eager' />
      </div>

      <div className='oops'>Oops! Something went wrong</div>
      {/* <pre className='errorType'>{props.error.message}</pre> */}
      <pre className='errorType'>
        If this problem persists please contact
        <a className='mailTo' href='mailto:support@flxlease.com'>
          {' '}
          support@flxlease.com
        </a>
      </pre>

      <div className='errorBtns'>
        <button className='tryAgainBtn' onClick={props.resetErrorBoundary}>
          Try again
        </button>
        <button className='goHomeBtn' onClick={() => navigate('/')}>
          Go Home
        </button>
      </div>
    </div>
  );
};
