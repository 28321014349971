import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Breadcrumb } from '@flxlease/flexinui';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { keyframes, styled } from '@mui/material/styles';

import { CityStates } from '../../../Graphql/Generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../Hooks/useApp';
import { closeAlert, selectAlert } from '../../../State/slices/alert';
import { selectCurrentUser } from '../../../State/slices/authentication';
import {
  GetAllCitiesAndStatesResponse,
  searchCity,
  setOwnerProperties,
  useLazyGetAllCitiesQuery,
  useLazyGetPropertiesByOwnerIdQuery,
} from '../../../State/slices/listing';
import { AccountMenu } from '../../components/accountMenu/AccountMenu';
import { SearchBar } from '../../components/searchBar/SearchBar';
import { useIsMobile } from '../../hooks/useIsMobile';
import { MobileLayout } from './MobileLayout';
import { getBreadCrumb } from './utils/navFunctions';

const drawerWidth = 240;

const LayoutContainer = styled('div')`
  display: flex;
`;

const MainContent = styled('main')`
  flex: 1;
`;

const RightTopNav = styled('div')`
  grid-column: 3;
  justify-self: end;
  align-self: self-start;
  padding-right: 24px;
  padding-top: 15px;
`;

const Column = styled('div')`
  flex-grow: 1;
  // margin-left: ${drawerWidth}px;
`;

const AppBarBreadCrumb = styled(Breadcrumb)`
  padding-left: 25px;
  padding-bottom: 15px;
  width: fit-content;
`;

const LeftTopNav = styled('div')`
  grid-column: 1;
`;

const SearchBarAlignment = styled('div')`
  grid-column: 2;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LogoImage = styled('img')`
  width: 200px;
  object-fit: cover;
`;

const Fade = styled('div')`
  animation: ${fadeIn} 2s ease-in;
`;

const IconContainer = styled('div')``;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export interface LayoutProps {
  children: React.ReactNode;
  onLegacyClick: () => void;
}

const showSearchBar = (pathname: string) => {
  return pathname === '/';
};

const redirectIfNotLoggedIn = (navigate: any) => {
  const id = sessionStorage.getItem('userId');
  if (!id) {
    sessionStorage.setItem('isBetaInBeta', 'false');
    navigate('/login');
  }
};

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction='up' />;
};

export const Layout = ({ children, onLegacyClick }: LayoutProps) => {
  const userState = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { message, isOpen: snackBarIsOpen, type: severity } = useAppSelector(selectAlert);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [
    getAllCitiesAndStates,
    { data: cities = { getAllCitiesAndStates: {} } as GetAllCitiesAndStatesResponse },
  ] = useLazyGetAllCitiesQuery();

  const [getPropertiesByOnwerId] = useLazyGetPropertiesByOwnerIdQuery();

  const id = userState?.user?.id || '';
  const loaded = useRef(false);

  // If the owner has any properties enable the dashboard
  useEffect(() => {
    const getPropertiesByOwnerIdAsync = async () => {
      const response = await getPropertiesByOnwerId({
        userId: id,
      });

      dispatch(setOwnerProperties(response?.data?.getPropertiesByOwnerId || []));
    };

    getPropertiesByOwnerIdAsync();
  }, [id]);

  useEffect(() => {
    const getAllCitiesAndStatesAsync = async () => {
      await getAllCitiesAndStates();
    };

    getAllCitiesAndStatesAsync();
  }, []);

  useEffect(() => {
    redirectIfNotLoggedIn(navigate);
  }, [id, navigate]);

  const onSelectedCityChanged = (date: string) => {
    console.log(date);
  };

  const search = (selectedCity: string) => {
    dispatch(searchCity(selectedCity || ''));
    navigate('/');
  };

  const isOnDashboard = location.pathname === '/dashboard';

  const dashboardItems = [
    {
      title: 'Dashboard',
      icon: <SpaceDashboardOutlinedIcon />,
    },
    {
      title: 'Overview',
      href: '/dashboard',
    },
  ];

  const items = isOnDashboard
    ? dashboardItems
    : [
        {
          title: 'Renting',
          icon: <ViewSidebarOutlinedIcon />,
          href: '',
        },
        {
          // @ts-ignore
          title: getBreadCrumb(location?.pathname?.toString() || '/'),
          href: location?.pathname?.toString() || '/',
        },
      ];

  return isMobile ? (
    <MobileLayout
      cities={cities?.getAllCitiesAndStates || ([] as Array<CityStates>)}
      search={search}
    >
      {children}
    </MobileLayout>
  ) : (
    <LayoutContainer>
      <MainContent>
        <AppBar
          position='sticky'
          sx={{
            // width: `calc(100% - ${drawerWidth}px)`,
            // ml: `${drawerWidth}px`,
            backgroundColor: 'white',
            color: '#262626',
            boxShadow: 'none',
            borderBottom: 'solid lightgray 1px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyItems: 'start',
            alignItems: 'center',
          }}
        >
          {id && (
            <>
              <LeftTopNav>
                <Toolbar>
                  <Typography
                    variant='h6'
                    noWrap
                    component='a'
                    onClick={() => navigate('/')}
                    sx={{
                      cursor: 'pointer',
                      mr: 2,
                      fontWeight: 700,
                      textDecoration: 'none',
                      fontSize: '2rem',
                    }}
                  >
                    <LogoImage
                      title='FLXlease logo'
                      src='/imgs/FLXLEASE_LOGO.webp'
                      alt='FLXlease logo'
                      loading='eager'
                    />
                  </Typography>
                </Toolbar>
                <AppBarBreadCrumb className='bread-crumb' separator='default' items={items} />
              </LeftTopNav>
              {showSearchBar(location.pathname) &&
                cities?.getAllCitiesAndStates?.length > 0 && (
                  <SearchBarAlignment>
                    <SearchBar
                      cities={cities.getAllCitiesAndStates}
                      search={search}
                      onSelectedCityChanged={onSelectedCityChanged}
                    />
                  </SearchBarAlignment>
                )}
            </>
          )}
          <RightTopNav>
            <AccountMenu onLegacyClick={onLegacyClick} />
          </RightTopNav>
        </AppBar>
        <Column>
          <Fade>{children}</Fade>
        </Column>
      </MainContent>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}
        elevation={3}
      >
        <Box sx={{ width: '100%' }}>
          <BottomNavigation
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <IconContainer>
              <Typography color='text.primary' variant='caption' sx={{ paddingLeft: '20px' }}>
                FLXlease Inc. All rights reserved © 2023
              </Typography>
            </IconContainer>
            <IconContainer>
              <BottomNavigationAction
                onClick={() => window.open('https://www.facebook.com/flxlease')}
                label='Facebook'
                icon={<FacebookIcon />}
              />
              <BottomNavigationAction
                onClick={() => window.open('https://www.instagram.com/flxlease.official/')}
                label='Instagram'
                icon={<InstagramIcon />}
              />
              <BottomNavigationAction
                onClick={() => window.open('https://twitter.com/flxlease')}
                label='Twitter'
                icon={<TwitterIcon />}
              />
              <BottomNavigationAction
                onClick={() => window.open('https://www.linkedin.com/company/flxlease/')}
                label='LinkedIn'
                icon={<LinkedInIcon />}
              />
            </IconContainer>
          </BottomNavigation>
        </Box>
      </Paper>
      <Snackbar
        open={snackBarIsOpen}
        onClose={() => dispatch(closeAlert())}
        TransitionComponent={SlideTransition}
        key='slideTransition'
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => dispatch(closeAlert())}
          severity={severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </LayoutContainer>
  );
};
