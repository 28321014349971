import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import Alert from '@mui/material/Alert';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import { CityStates } from '../../../Graphql/Generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../Hooks/useApp';
import { closeAlert, selectAlert } from '../../../State/slices/alert';
import { logout, selectCurrentUser } from '../../../State/slices/authentication';
import { SearchBar } from '../../components/searchBar/SearchBar';

const UserWelcome = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  flex-shrink: 0;
  justify-content: center;
  padding-top: 0;
`;

const Scroll = styled('div')`
  overflow-y: scroll;
`;

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction='up' />;
};

export interface IMobileLayoutProps {
  children: React.ReactNode;
  cities: Array<CityStates>;
  onSelectedCityChanged?: (city: string) => void;
  search: (selectedCity: string) => void;
}

export const MobileLayout = ({
  cities,
  onSelectedCityChanged,
  search,
  children,
}: IMobileLayoutProps) => {
  const userState = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const { user } = userState;
  const { message, isOpen: snackBarIsOpen, type: severity } = useAppSelector(selectAlert);

  const handleLogOut = () => {
    sessionStorage.setItem('userId', '');
    sessionStorage.setItem('userEmail', '');
    sessionStorage.setItem('userFirstName', '');
    sessionStorage.setItem('userLastName', '');
    sessionStorage.setItem('isInBeta', 'false');

    dispatch(logout());

    window.location.href = '/login';
  };

  const navigate = useNavigate();
  const location = useLocation();

  const id = sessionStorage.getItem('userId');
  const showSearchBar = location.pathname === '/' && id;

  return (
    <>
      <Drawer
        variant='permanent'
        anchor='bottom'
        sx={{
          '& .MuiDrawer-paper': {
            height: `100%`,
            overflowY: 'hidden',
            boxSizing: 'border-box',
            borderRadius: '20px 20px 0 0',
            boxShadow:
              '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        <UserWelcome>
          {showSearchBar && cities.length > 0 && (
            <SearchBar
              cities={cities}
              search={search}
              onSelectedCityChanged={onSelectedCityChanged}
              isMobile={true}
            />
          )}
          <Paper
            sx={{
              transition: 'none',
              borderRadius: 'none',
              boxShadow: 'none',
              paddingTop: '10px',
              paddingBottom: '5px',
              width: '100%',
            }}
            elevation={3}
          >
            <Box>
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                <BottomNavigationAction
                  label='List'
                  icon={<HolidayVillageOutlinedIcon fontSize='large' />}
                  onClick={() => {
                    navigate('/');
                  }}
                />
                {/* <BottomNavigationAction
                  label='Add'
                  icon={<AddHomeOutlinedIcon fontSize='large' />}
                  onClick={() => {
                    navigate('/list-your-home');
                  }}
                /> */}
                {id && (
                  <BottomNavigationAction
                    label='Contact'
                    icon={<ContactPageOutlinedIcon fontSize='large' />}
                    onClick={() => {
                      navigate('/contact-us');
                    }}
                  />
                )}
                <BottomNavigationAction
                  label='Blog'
                  icon={<ArticleOutlinedIcon fontSize='large' />}
                  onClick={() => {
                    window.location.href = 'https://blog.flxlease.com/blog';
                  }}
                />
                {id && (
                  <BottomNavigationAction
                    icon={<LogoutIcon fontSize='large' />}
                    label='Logout'
                    onClick={handleLogOut}
                  />
                )}
                {/* <BottomNavigationAction
                  label='Profile'
                  icon={<AccountCircleOutlinedIcon fontSize='large' />}
                  onClick={() => {
                    navigate('/profile');
                  }}
                /> */}
              </BottomNavigation>
            </Box>
          </Paper>
        </UserWelcome>
        <Divider />
        <Scroll id='mobile-scroll'>{children}</Scroll>
        <Snackbar
          open={snackBarIsOpen}
          onClose={() => dispatch(closeAlert())}
          TransitionComponent={SlideTransition}
          key='slideTransition'
          autoHideDuration={3000}
        >
          <Alert
            onClose={() => dispatch(closeAlert())}
            // @ts-ignore
            severity={severity}
            variant='filled'
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Drawer>
    </>
  );
};
