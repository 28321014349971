import { Suspense, lazy, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Client, Provider, cacheExchange, fetchExchange } from 'urql';

import './App.css';
import store from './State/store';
import { Fallback } from './legacy/Pages/Errors/Fallback';
import { AppV2 } from './new/App';

const Footer = lazy(() => import('./legacy/Components/Footer/Footer'));
const SignIn = lazy(() => import('./legacy/Components/Login/SignIn'));
const SignUp = lazy(() => import('./legacy/Components/Login/SignUp'));
const Navigation = lazy(() => import('./legacy/Components/Navigation/Navigation'));
const About = lazy(() => import('./legacy/Pages/About/About'));
const Dashboard = lazy(() => import('./legacy/Pages/Dashboard/Dashboard'));
const DisplayProperty = lazy(() => import('./legacy/Pages/DisplayProperty/DisplayProperty'));

const FAQ = lazy(() => import('./legacy/Pages/FAQ/FAQ'));
const Home = lazy(() => import('./legacy/Pages/Home/Home'));
const Listings = lazy(() => import('./legacy/Pages/Listings/Listings'));
const ListYourHome = lazy(() => import('./legacy/Pages/ListYourHome/ListYourHome'));
const Profile = lazy(() => import('./legacy/Pages/Profile/Profile'));
const StillLooking = lazy(() => import('./legacy/Pages/StillLooking/StillLooking'));

export const App = () => {
  // TODO: App toggle logic here...
  const isInBeta = Boolean(
    JSON.parse(sessionStorage?.getItem('isInBeta')?.toLocaleLowerCase() || 'false'),
  );
  const [isBeta, setIsBeta] = useState(isInBeta);

  useEffect(() => {
    sessionStorage.setItem('isInBeta', isBeta.toString());
  }, [isBeta]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let uri;
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    uri = process.env.REACT_APP_DEV_SERVER_URI;
  } else {
    uri = process.env.REACT_APP_PROD_SERVER_URI;
  }

  const client = new Client({
    url: uri ? uri : 'http://127.0.0.1:4000/graphql',
    exchanges: [cacheExchange, fetchExchange],
    requestPolicy: 'network-only',
  });

  const onLegacyClick = () => {
    setIsBeta((prev: boolean) => !prev);
    window.location.href = '/';
  };

  const LoadingCircle = () => {
    return (
      <div className='loadingCircle'>
        <div>Loading...</div>
      </div>
    );
  };

  return isBeta ? (
    <AppV2 onLegacyClick={onLegacyClick} />
  ) : (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={() => {
        window.location.reload();
      }}
      resetKeys={['someKey']}
    >
      <div className='App'>
        <StoreProvider store={store}>
          <Provider value={client}>
            <BrowserRouter>
              <Navigation onLegacyClick={onLegacyClick} />
              <Routes>
                <Route
                  path='/'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <Home />
                    </Suspense>
                  }
                />
                <Route
                  path='/listings'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <Listings />
                    </Suspense>
                  }
                />
                <Route
                  path='/property/:propertyTitle?'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <DisplayProperty />
                    </Suspense>
                  }
                />
                <Route
                  path='/about'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <About />
                    </Suspense>
                  }
                />
                <Route
                  path='/list-your-home'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <ListYourHome />
                    </Suspense>
                  }
                />
                <Route
                  path='/login'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <SignIn />
                    </Suspense>
                  }
                />
                <Route
                  path='/sign-up'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <SignUp />
                    </Suspense>
                  }
                />
                <Route
                  path='/profile'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <Profile />
                    </Suspense>
                  }
                />
                <Route
                  path='/still-looking'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <StillLooking />
                    </Suspense>
                  }
                />
                <Route
                  path='/frequently-asked-questions'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <FAQ />
                    </Suspense>
                  }
                />
                <Route
                  path='/admin-dashboard'
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <Dashboard />
                    </Suspense>
                  }
                />
              </Routes>
              <Footer />
            </BrowserRouter>
          </Provider>
        </StoreProvider>
      </div>
    </ErrorBoundary>
  );
};
