export const getApiUrl = () => {
  let url = 'http://localhost:4000/graphql';

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    url = process.env.REACT_APP_DEV_SERVER_URI ?? url;
  } else {
    url = process.env.REACT_APP_PROD_SERVER_URI ?? url;
  }

  return url;
};

export const getRestApiUrl = () => {
  let url = 'http://localhost:4000/api';

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    url = process.env.REACT_APP_DEV_API_SERVER ?? url;
  } else {
    url = process.env.REACT_APP_PROD_API_SERVER ?? url;
  }

  return url;
};
