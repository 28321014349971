import { Middleware, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { CityStates, Property, PropertyInput } from '../../Graphql/Generated/graphql';
import {
  CREATE_RESERVATION,
  LIKE_PROPERTY,
  SEND_REFERRAL,
  UNLIKE_PROPERTY,
  UPDATE_PROPERTY,
} from '../../Graphql/mutations';
import {
  GET_ALL_CITIES_AND_STATES,
  GET_ALL_PROPERTIES,
  GET_PROPERTIES_BY_OWNER_ID,
  GET_PROPERTY_BY_ID,
  GET_PROPERTY_BY_NAME,
  GET_PROPERTY_BY_TITLE,
  IS_PROPERTY_LIKED,
} from '../../Graphql/queries';
import amplitudeEvents from '../../Utils/Tracking/amplitude.events';
import { getApiUrl } from '../../Utils/urls';
import { RootState } from '../store';

export interface IRenter {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  idealCity: string;
  state: string;
  numberOfPets: number;
  furnished: boolean;
  budget: string;
  message: string;
}

export interface IListingState {
  listings: Array<Property>;
  ownerProperties: Array<Property>;
  searchCity: string;
  renter: IRenter;
  listing: Property;
  inquiry: boolean;
  file: string;
}

export interface LikePropertyInput {
  propertyId: string;
  userId: string;
}

export interface GetPropertyLikedStatusResponse {
  isPropertyLiked: boolean;
}

export interface GetPropertyByIdInput {
  propertyId: string;
}

export interface GetPropertyByIdResponse {
  getPropertyById: Property;
}

export interface CreateReservationInput {
  propertyId: string;
  userId: string;
  start: string;
  end: string;
  room: string;
  totalCost: number;
  paymentSchedule: string;
  message: string;
  phone: string;
}

// export type MutationResponse = 'SUCCESS' | 'FAILURE';
export interface MutationResponse {
  message: string;
  messageType: 'SUCCESS' | 'FAILURE';
}

export interface CreateReservationResponse {
  createReservation: MutationResponse;
}

export interface CreateLikedPropertyInput {
  likeProperty: MutationResponse;
}

export interface CreateUnlikedPropertyInput {
  unlikeProperty: MutationResponse;
}

export interface CreateReferralInput {
  referral: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    idealCity: string;
    state: string;
    furnished: 'Furnished' | 'Unfurnished';
    pets: number;
    budget: string;
    timeline: string;
  };
}

export interface CreateReferralResponse {
  sendReferral: MutationResponse;
}

export interface GetPropertyByTitleInput {
  propertyTitle: string;
}

export interface GetPropertyByTitleResponse {
  getPropertyByTitle: Property;
}

export interface GetPropertyByNameInput {
  propertyName: string;
}

export interface GetPropertyByNameResponse {
  getPropertyByName: Property;
}

export interface GetPropertiesByOwnerIdInput {
  userId: string;
}

export interface GetPropertiesByOwnerIdResponse {
  getPropertiesByOwnerId: Property[];
}

// GET_ALL_PROPERTIES

export interface GetAllPropertiesInput {
  searchCity: string;
  searchStart: string;
  searchEnd: string;
  pagination: {
    paginate: boolean;
    page: number;
    pageSize: number;
  };
}

export interface GetAllPropertiesResponse {
  getAllProperties: Array<Property>;
}

export interface GetAllCitiesAndStatesResponse {
  getAllCitiesAndStates: Array<CityStates>;
}

export interface UpdatePropertyInput {
  propertyInfo: PropertyInput;
  userId: string;
}

export interface UpdatePropertyResponse {
  updateProperty: MutationResponse;
}

export const listingApi = createApi({
  reducerPath: 'listingApi',
  baseQuery: graphqlRequestBaseQuery({ url: getApiUrl() }),
  endpoints: (builder) => ({
    getPropertyLikedStatus: builder.query<GetPropertyLikedStatusResponse, LikePropertyInput>({
      query: ({ propertyId, userId }) => ({
        document: IS_PROPERTY_LIKED,
        variables: { propertyId, userId },
      }),
    }),
    getPropertyById: builder.query<GetPropertyByIdResponse, GetPropertyByIdInput>({
      query: ({ propertyId }) => ({
        document: GET_PROPERTY_BY_ID,
        variables: { propertyId },
      }),
    }),
    getPropertyByTitle: builder.query<GetPropertyByTitleResponse, GetPropertyByTitleInput>({
      query: ({ propertyTitle }) => ({
        document: GET_PROPERTY_BY_TITLE,
        variables: { propertyTitle },
      }),
    }),
    getPropertyByName: builder.query<GetPropertyByNameResponse, GetPropertyByNameInput>({
      query: ({ propertyName }) => ({
        document: GET_PROPERTY_BY_NAME,
        variables: { propertyName },
      }),
    }),
    getPropertiesByOwnerId: builder.query<
      GetPropertiesByOwnerIdResponse,
      GetPropertiesByOwnerIdInput
    >({
      query: ({ userId }) => ({
        document: GET_PROPERTIES_BY_OWNER_ID,
        variables: { userId },
      }),
    }),
    createReservation: builder.mutation<CreateReservationResponse, CreateReservationInput>({
      query: (data) => ({
        document: CREATE_RESERVATION,
        variables: data,
      }),
    }),
    likeProperty: builder.mutation<CreateLikedPropertyInput, LikePropertyInput>({
      query: ({ propertyId, userId }) => ({
        document: LIKE_PROPERTY,
        variables: { propertyId, userId },
      }),
    }),
    unlikeProperty: builder.mutation<CreateUnlikedPropertyInput, LikePropertyInput>({
      query: ({ propertyId, userId }) => ({
        document: UNLIKE_PROPERTY,
        variables: { propertyId, userId },
      }),
    }),
    getAllPropertiesByCity: builder.query<GetAllPropertiesResponse, GetAllPropertiesInput>({
      query: ({ searchCity, searchStart, searchEnd, pagination }) => ({
        document: GET_ALL_PROPERTIES,
        variables: { searchCity, searchStart, searchEnd, pagination },
      }),
    }),
    createReferral: builder.mutation<CreateReferralResponse, CreateReferralInput>({
      query: ({ referral }) => ({
        document: SEND_REFERRAL,
        variables: { referral },
      }),
    }),
    updateProperty: builder.mutation<UpdatePropertyResponse, UpdatePropertyInput>({
      query: ({ propertyInfo, userId }) => ({
        document: UPDATE_PROPERTY,
        variables: { propertyInfo, userId },
      }),
    }),
    getAllCities: builder.query<GetAllCitiesAndStatesResponse, void>({
      query: () => ({
        document: GET_ALL_CITIES_AND_STATES,
        variables: {},
      }),
    }),
  }),
});

export const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    renter: {},
    listing: {},
    ownerProperties: [] as Array<Property>,
    inquiry: false,
    searchCity: '',
    file: '',
    listings: [] as Array<Property>,
  } as IListingState,
  reducers: {
    resetListings: (state) => {
      state.listings = [];
    },
    searchListings: (state, action) => {
      state.listings = [...state.listings, ...action.payload];
    },
    setOwnerProperties: (state, action) => {
      state.ownerProperties = action.payload;
    },
    currentListing: (state, action) => {
      state.listing = action.payload;
    },
    sendInquiry: (state, action) => {
      state.inquiry = action.payload;
    },
    listYourHome: (state, action) => {
      state.listing = action.payload.listing;
      state.file = action.payload.file;
    },
    stillLooking: (state, action) => {
      state.renter = action.payload;
    },
    searchCity: (state, action) => {
      state.searchCity = action.payload;
    },
  },
});

export const listingMiddleware: Middleware = (store) => (next) => (action: any) => {
  if (listingSlice.actions.sendInquiry.match(action)) {
    const trackListing = {
      id: action.payload.id,
      title: action.payload.title,
      address: action.payload.addressLine1,
      city: action.payload.city,
      state: action.payload.state,
      zipCode: action.payload.zipCode,
      ownerId: action.payload.ownerId,
    };

    amplitudeEvents.sendInquiry(trackListing);
  }

  if (listingSlice.actions.listYourHome.match(action)) {
    amplitudeEvents.listProperty(action.payload.listing);
  }

  if (listingSlice.actions.stillLooking.match(action)) {
    amplitudeEvents.stillLooking(action.payload);
  }

  return next(action);
};

export const {
  currentListing,
  sendInquiry,
  listYourHome,
  stillLooking,
  searchListings,
  resetListings,
  searchCity,
  setOwnerProperties,
} = listingSlice.actions;

export const selectCurrentListing = (state: RootState) => state.listing;
export const selectListings = (state: RootState) => state.listing.listings;
export const selectSearchCity = (state: RootState) => state.listing.searchCity;
export const selectOwnerProperties = (state: RootState) => state.listing.ownerProperties;

export const {
  useCreateReservationMutation,
  useGetPropertyByIdQuery,
  useLazyGetPropertyLikedStatusQuery,
  useLazyGetPropertyByIdQuery,
  useGetPropertyLikedStatusQuery,
  useLikePropertyMutation,
  useCreateReferralMutation,
  useUnlikePropertyMutation,
  useGetAllPropertiesByCityQuery,
  useLazyGetAllPropertiesByCityQuery,
  useGetPropertyByTitleQuery,
  useLazyGetPropertyByTitleQuery,
  useGetPropertyByNameQuery,
  useLazyGetPropertyByNameQuery,
  useGetAllCitiesQuery,
  useLazyGetAllCitiesQuery,
  useGetPropertiesByOwnerIdQuery,
  useLazyGetPropertiesByOwnerIdQuery,
  useUpdatePropertyMutation,
} = listingApi;
