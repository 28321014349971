import * as amplitude from '@amplitude/analytics-browser';

export const EVENT_TYPES = {
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
  LOGOUT: 'LOGOUT',
  SEND_INQUIRY: 'SEND_INQUIRY',
  STILL_LOOKING: 'STILL_LOOKING',
  LIST_PROPERTY: 'LIST_PROPERTY',
} as const;

export type EventTypes = (typeof EVENT_TYPES)[keyof typeof EVENT_TYPES];

export type ITrackerEvent = {
  name: EventTypes;
  properties?: Record<string, any>;
};

export class AmplitudeTracker {
  static init(apiKey: string, optOut = false) {
    amplitude.init(apiKey, {
      defaultTracking: {
        pageViews: true,
        sessions: true,
      },
      optOut,
    });
  }

  static identifyUser(userId: string, userProperties: Record<string, any>) {
    this.setUserId(userId);

    const identify = new amplitude.Identify();
    Object.keys(userProperties).forEach((key) => {
      identify.set(key, userProperties[key]);
    });

    amplitude.identify(identify);
  }

  static track(event: ITrackerEvent) {
    amplitude.track(event.name, { ...event.properties });
  }

  static setUserId(userId: string) {
    amplitude.setUserId(userId);
  }

  static setSessionId(sessionId: number) {
    amplitude.setSessionId(sessionId);
  }

  static logEvent(event: ITrackerEvent) {
    amplitude.logEvent(event.name, event.properties);
  }
}

export { amplitude };
